import {createContext, useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { fetchTokens, fetchRefreshToken } from '../api/auth';
// MUI Components
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({children}) => {
    // Alerts open/closed with message and severity
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');

    let [user, setUser] = useState(
        () => (
            localStorage.getItem('authTokens') ?
                jwtDecode(localStorage.getItem('authTokens')) : null)
    )
    let [authTokens, setAuthTokens] = useState(
        () => (localStorage.getItem('authTokens') ?
            JSON.parse(localStorage.getItem('authTokens')) : null)
    )
    let [loading, setLoading] = useState(true)
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate()

    // Alert handling
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    let loginUser = async (e) => {
        e.preventDefault()
        setIsLoading(true);
        const response = await fetchTokens(e.target.username.value, e.target.password.value);

        let data = await response.json();

        if(response.status === 200){
            localStorage.setItem('authTokens', JSON.stringify(data));
            setAuthTokens(data)
            setUser(jwtDecode(data.access))
            navigate('/')
            setMessage('Successfully logged in!');
            setSeverity('success');
            setOpen(true);
            setIsLoading(false);
        } else {
            setMessage('Something went wrong while logging in!');
            setSeverity('error');
            setOpen(true);
            setIsLoading(false);
        }
    }

    // logout user should also set message, severity and verify to user that the process was (un)successful.
    let logoutUser = () => {
        // e.preventDefault()
        try {
            localStorage.removeItem('authTokens')
            setAuthTokens(null)
            setUser(null)
            setMessage('Successfully logged out!');
            setSeverity('success');
            setOpen(true);
        } catch(err) {
            setMessage('Something went wrong while logging out!');
            setSeverity('error');
            setOpen(true);
        }
        // navigate('/')
    }


    const updateToken = useCallback(async () => {
        const response = await fetchRefreshToken(authTokens);

        const data = await response.json();
        if (response.status === 200) {
            setAuthTokens(data)
            setUser(jwtDecode(data.access))
            localStorage.setItem('authTokens', JSON.stringify(data))
        } else {
            logoutUser()
        }

        if(loading){
            setLoading(false)
        }
    }, [authTokens, loading]);


    let contextData = {
        user: user,
        authTokens: authTokens,
        loginUser: loginUser,
        logoutUser: logoutUser,
        loading: loading,
        isLoading: isLoading
    }

    useEffect(() => {

        if(loading && authTokens){
            updateToken()
        }

        const REFRESH_INTERVAL = 1000 * 60 * 30 // 30 minutes
        let interval = setInterval(()=> {
            if(authTokens){
                updateToken()
            }
        }, REFRESH_INTERVAL)
        return () => clearInterval(interval)

    }, [authTokens, loading, updateToken])

    return(
        <AuthContext.Provider value={contextData}>
            {children}
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                key='top-left'
            >
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </AuthContext.Provider>
    )
}
