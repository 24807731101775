import { createTheme } from '@mui/material/styles';

// export const lightTheme = createTheme({
//   palette: {
//     mode: 'light',
//     primary: {
//       main: '#DC5F00',
//     },
//     secondary: {
//       main: '#EEEEEE',
//     },
//     warning: {
//       main: '#acb907',
//     },
//     success: {
//       main: '#38df00',
//     },
//     error: {
//       main: '#df3800',
//     },
//   },
//   typography: {
//     caption: {
//       fontFamily: 'Rubik',
//     },
//     subtitle1: {
//       fontFamily: 'Rubik',
//     },
//     subtitle2: {
//       fontFamily: 'Rubik',
//     },
//     body1: {
//       fontFamily: 'Rubik',
//     },
//     body2: {
//       fontFamily: 'Rubik',
//     },
//     fontFamily: 'Roboto, Rubik',
//   },
//   components: {
//     MuiSwitch: {
//       styleOverrides: {
//         root: {
//           width: 46,
//           height: 27,
//           padding: 0,
//           margin: 8,
//         },
//         switchBase: {
//           padding: 1,
//           '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
//             transform: 'translateX(16px)',
//             color: '#fff',
//             '& + $track': {
//               opacity: 1,
//               border: 'none',
//             },
//           },
//         },
//         thumb: {
//           width: 24,
//           height: 24,
//         },
//         track: {
//           borderRadius: 13,
//           border: '1px solid #bdbdbd',
//           backgroundColor: '#fafafa',
//           opacity: 1,
//           transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
//         },
//       },
//     },
//   },
// });

export const darkTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1900
    },
  },
  action: {
    active: '#fff',
    hover: 'rgba(255,255,255,0.08)',
    hoverOpacity: 0.08,
    selected: 'rgba(255,255,255,0.16)',
    selectedOpacity: 0.16,
    disabled: 'rgba(255,255,255,0.3)',
    disabledBackground: 'rgba(255,255,255,0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(255,255,255,0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.24
  },
  shape: {
    borderRadius: 4
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#DC5F00',
    },
    secondary: {
      main: '#EEEEEE',
    },
    tertiary: {
      main: '#121212'
    },
    warning: {
      main: '#acb907',
    },
    success: {
      // main: '#38df00', - old
      main: '#09870e',
    },
    error: {
      // main: '#df3800', - old
      main: '#a10505',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF',
      tertiary: '#DC5F00'
    }
  },
  typography: {
    fontFamily: "'Roboto', 'Rubik', sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: "'Roboto', 'Rubik', sans-serif",
      fontWeigh: 300,
      fontSize: "6rem",
      lineHeight: 1.167,
      letterSpacing: "-0.01562em"
    },
    h2: {
      fontFamily: "'Roboto', 'Rubik', sans-serif",
      fontWeigh: 300,
      fontSize: "3.75rem",
      lineHeight: 1.2,
      letterSpacing: "-0.00833em"
    },
    h3: {
      fontFamily: "'Roboto', 'Rubik', sans-serif",
      fontWeigh: 400,
      fontSize: "3rem",
      lineHeight: 1.167,
      letterSpacing: "0em"
    },
    h4: {
      fontFamily: "'Roboto', 'Rubik', sans-serif",
      fontWeigh: 400,
      fontSize: "2.125rem",
      lineHeight: 1.235,
      letterSpacing: "0.00735em"
    },
    h5: {
      fontFamily: "'Roboto', 'Rubik', sans-serif",
      fontWeigh: 400,
      fontSize: "1.5rem",
      lineHeight: 1.334,
      letterSpacing: "0em"
    },
    subtitle1: {
      fontFamily: "'Roboto', 'Rubik', sans-serif",
      fontWeigh: 400,
      fontSize: "1rem",
      lineHeight: 1.75,
      letterSpacing: "0.00938em"
    },
    subtitle2: {
      fontFamily: "'Roboto', 'Rubik', sans-serif",
      fontWeigh: 500,
      fontSize: "0.875rem",
      lineHeight: 1.57,
      letterSpacing: "0.00714em"
    },
    body1: {
      fontFamily: "'Roboto', 'Rubik', sans-serif",
      fontWeigh: 400,
      fontSize: "1rem",
      lineHeight: 1.5,
      letterSpacing: "0.00938em"
    },
    body2: {
      fontFamily: "'Roboto', 'Rubik', sans-serif",
      fontWeigh: 400,
      fontSize: "0.875rem",
      lineHeight: 1.43,
      letterSpacing: "0.01071em"
    },
    button: {
      fontFamily: "'Roboto', 'Rubik', sans-serif",
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.75,
      letterSpacing: "0.02857em",
      textTransform: "uppercase"
    },
    caption: {
      fontFamily: "'Roboto', 'Rubik', sans-serif",
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.66,
      letterSpacing: "0.03333em"
    }
  },
  zIndex: {
    fab: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500
  }
});