// login procedure
export async function fetchTokens(username, password) {
    const response = await fetch(`${process.env.REACT_APP_SERVER_API_BASE_URL}/token/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({username: username, password: password})
    });

    return response
}

export async function fetchRefreshToken(authTokens) {
    const response = await fetch(`${process.env.REACT_APP_SERVER_API_BASE_URL}/token/refresh/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({refresh:authTokens?.refresh})
    });

    return response
}

