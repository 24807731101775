// React Core and Router
import React, { useContext, useState } from 'react';
import {useLocation, useNavigate} from "react-router-dom";

// Custom Context
import AuthContext from "../context/AuthContext";

// Custom Components
import SocialIconButtons from "../components/SocialIconButtons";

// MUI Components
import {
    Box,
    Button,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Stack,
    Tab,
    Tabs,
    useMediaQuery
} from "@mui/material";

// MUI Icons
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import LogoutIcon from '@mui/icons-material/Logout';

const Navigation = ({ theme, handleOpenModal }) => {
    const { user, logoutUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const menuItems = [
        {
            label: 'Home',
            path: '/',
            icon: <HomeIcon />
        },
        {
            label: 'About',
            path: '/about',
            icon: <InfoIcon />
        }
    ];

    // media query relevant variables
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Mobile View - Menu
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    // Tab handler for path navigation
    const handleChange = (event, newValue) => {
        navigate(newValue);
        if (isMobile) handleMenuClose();
    }

    // Check if the current path has a corresponding Tab
    const tabPaths = menuItems.map(item => item.path);
    const currentPathHasTab = tabPaths.includes(location.pathname);

    // added mobile responsiveness
    // added MenuIcon on mobile view and dropdown options for List Buttons
    // (desktop/mobile) added create post and logout button => at bottom of Menu on mobile

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: 2 }}>
            {/* Logo on the left */}
            <Box onClick={() => navigate('/')}>
                <img src="logo192.png" width="40" height="40" alt="blog-logo"/>
            </Box>
            {/* Tabs and icons on the right */}
            <Stack direction="row" spacing={2} alignItems="center">
                {isMobile ? (
                    <>
                        <SocialIconButtons />
                        <IconButton edge="start" aria-label="menu" onClick={handleMenuOpen}>
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            {menuItems.map((item) => (
                                <MenuItem key={item.label} onClick={(event) => handleChange(event, item.path)}>
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    {item.label}
                                </MenuItem>
                            ))}
                            {user && [
                                    <MenuItem key='create-post' onClick={handleOpenModal}>
                                        <ListItemIcon>
                                            <AddIcon />
                                        </ListItemIcon>
                                        Create Post
                                    </MenuItem>,
                                    <MenuItem key='logout' onClick={logoutUser}>
                                        <ListItemIcon>
                                            <LogoutIcon />
                                        </ListItemIcon>
                                        Logout
                                    </MenuItem>
                            ]}
                        </Menu>
                    </>
                ) : (
                    <>
                        <Tabs value={currentPathHasTab ? location.pathname : false} onChange={handleChange}>
                            {menuItems.map((item, index) => (
                                <Tab
                                    key={index}
                                    label={item.label}
                                    value={item.path}
                                    sx={{
                                        fontWeight: 'bold',
                                        color: location.pathname === item.path ? theme.palette.text.tertiary : theme.palette.text.primary
                                    }}
                                />
                            ))}
                        </Tabs>
                        {/* Conditional render (authenticated view) => create post and logout buttons */}
                        {user && (
                            <>
                                <Button variant='contained' color='success' startIcon={<AddIcon />} onClick={handleOpenModal}>
                                    Create Post
                                </Button>
                                <Button variant='contained' color='error' onClick={logoutUser} startIcon={<LogoutIcon />}>
                                    Logout
                                </Button>
                            </>
                        )}
                    </>
                )}
                {!isMobile && (
                    <SocialIconButtons />
                )}
            </Stack>
        </Stack>
    )
}
export default Navigation