import React, { useContext } from 'react';
import AuthContext from "../context/AuthContext";
import {
    Avatar,
    Box,
    Button,
    Card,
    CircularProgress,
    TextField,
    Typography,
} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';


const Login = () => {

    let {loginUser, user, isLoading} = useContext(AuthContext)

    return (
        <>
            <Box
                sx={{
                    p: 2
                }}
            >
                {/* Render the login components */}
                <Card sx={{ p: 2 }}>
                    <Box
                        sx={{
                            my: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box component="form" onSubmit={loginUser} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                disabled={!!user}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                disabled={!!user}
                            />
                            {/* loading icon to button after pressed - not working... */}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={!!user || isLoading}
                            >
                                {isLoading ? <CircularProgress size={24} /> : 'Sign In'}
                            </Button>
                        </Box>
                    </Box>
                </Card>
            </Box>
        </>
    )
}
export default Login