import React from 'react';
import Card from '@mui/material/Card';

const SkillCard = ({ skill }) => {

    return (
        <Card
            sx={{
                p: 1,
                borderRadius: 2,
                border: "1px solid #333333",
                backgroundColor: '#232323',
                boxShadow: "0 2px 3px #0000003d",
            }}
        >
            {/* Prop will be passed from the About page: skill.name */}
            {skill.name}
        </Card>
    )
}
export default SkillCard;
