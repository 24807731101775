// individual post calls here (use id or slug)
export async function fetchPost(id) {
    // set environment variable for api endpoint
    // I think this might get called too often for some reason.
    return await fetch(`${process.env.REACT_APP_SERVER_API_BASE_URL}/post/${id}/`)
        .then((response) => response.json())
        .then((json) => json);
}

// create a post
export async function createPost(postData, token) {
    const response = await fetch(`${process.env.REACT_APP_SERVER_API_BASE_URL}/post/create/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(postData),
    });

    return response.json();
}

// delete a post
export async function deletePost(id, token) {
    const response = await fetch(`${process.env.REACT_APP_SERVER_API_BASE_URL}/post/${id}/delete/`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    })

    return response.status
}