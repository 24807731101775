import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import DOMPurify from "dompurify";
import {
    Box,
    Divider,
    Card,
    CardContent,
    Grid,
    IconButton,
    Skeleton,
} from "@mui/material";
import SkillCard from '../components/SkillCard';
import ProjectCard from "../components/ProjectCard";
import ConstructionIcon from '@mui/icons-material/Construction';
import InfoIcon from '@mui/icons-material/Info';
import Typography from "@mui/material/Typography";
import { fetchAbout } from '../api/about';


const About = () => {
    const navigate = useNavigate();
    const [aboutData, setAboutData] = useState(null);

    useEffect(() => {
        // load data and timeout for 3 seconds
        const fetchData = async () => {
            setTimeout(async () => {
                const data = await fetchAbout();
                setAboutData(data[0]);
            }, 3000)
        };
        fetchData();
    }, []);

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', p: 2 }}>
                {/* About me - info about blog's purpose and my journey */}
                <Card sx={{ display: 'flex', p: 3, my: 1 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        {/* Secret Login navigation */}
                        <IconButton
                            sx={{
                                cursor: 'default',
                            }}
                            onClick={() => navigate('/login')}
                            size='small'
                            disableRipple
                            edge={'start'}
                        >
                            <InfoIcon fontSize="large"/>
                        </IconButton>
                        <Typography>Me</Typography>
                    </Box>
                    <Divider orientation="vertical" flexItem sx={{ mx: 2 }}/>
                    <Box>
                        <CardContent>
                            {/* About - HTML content should be pulled into here */}
                            {aboutData ? (
                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(aboutData.html) }} />
                            ) : (
                                <Skeleton variant='text' width={300} height={200}/>
                            )}
                        </CardContent>
                    </Box>
                </Card>
                {/* Skills - my technologies */}
                <Card sx={{ display: 'flex', p: 3, my: 1 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <ConstructionIcon fontSize="large"/>
                        <Typography>Stack</Typography>
                    </Box>
                    <Divider orientation="vertical" flexItem sx={{ mx: 2 }}/>
                    <Box>
                        <CardContent
                            sx={{
                                gap: '6px',
                                display: 'flex',
                                flexWrap: 'wrap',
                                fontSize: '14px'
                            }}
                        >
                            {/* map the skills from DB to SkillCard components */}
                            {aboutData ? aboutData.skills.map((skill) => (
                                <SkillCard key={skill.id} skill={skill}/>
                            )): <Skeleton variant='text' width={300} height={150}/> }
                        </CardContent>
                    </Box>
                </Card>
                {/* Projects - GitHub links or live demos */}
                <Box sx={{ my: 1 }}>
                    <Grid container spacing={2}>
                        {aboutData ? aboutData.projects.map((project) => (
                            <Grid item xs={12} md={12} lg={12} xxl={6} key={project.id}>
                                <ProjectCard project={project} key={project.id}/>
                            </Grid>
                        )) : [...Array(4)].map((_, index) => (
                            <Grid item xs={12} md={12} lg={12} xxl={6} key={index}>
                                <ProjectCard project={null} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </>
    )
}
export default About
