import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { Button, Grid, IconButton, Paper, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteConfirmation from './DeleteConfirmation';
import { formatTime } from '../utils';


// Title Bar: Back navigation, title, date/time of post, edit and delete buttons
const PostTitleBar = ({ post }) => {
    const theme = useTheme();
    let { user } = useContext(AuthContext);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const backButtonWidth = isSmallScreen ? 12 : 1;
    const titleBarWidth = isSmallScreen ? 12 : 11;

    // back navigation
    let navigate = useNavigate();
    function handleBack() {
        navigate("/");
    }
    // published_at = str
    // console.log(new Date(post.published_at).toLocaleString('en-gb', { timeZone: 'UTC' }))
    return (
        <>
            <Grid
                container
                spacing={1}
                sx={{
                    p: 2,
                    mt: 1
                }}
            >
                <Grid
                    item
                    xs={backButtonWidth}
                    sx={{
                        display: 'flex',
                    }}
                >
                    <Button
                        onClick={handleBack}
                        sx={{
                            backgroundColor: theme.palette.tertiary.main,
                            textAlign: 'center',
                            minHeight: '100%',
                            minWidth: '100%',
                        }}
                        variant="contained"
                    >
                        <ArrowBackIcon />
                    </Button>
                </Grid>
                <Grid
                    item
                    xs={titleBarWidth}
                >
                    <Paper
                        sx={{
                            px: 2,
                            textAlign: 'left'
                        }}
                        elevation={1}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid
                                item
                                sx={{
                                    m: 1
                                }}
                            >
                                {/* Title */}
                                <Typography
                                    variant="h5"
                                    component="h3"
                                >
                                    {post.title}
                                </Typography>
                                {/*  Date Published  */}
                                {formatTime(post.published_at)}
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    m: 1
                                }}
                            >
                                {user && (
                                    <>
                                        <IconButton>
                                            <EditIcon />
                                        </IconButton>
                                        <DeleteConfirmation postId={post.id}/>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}
export default PostTitleBar
