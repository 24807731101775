// PostModal.js
import React from 'react';
import { Box, IconButton, Modal, Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PostForm from './PostForm';



const PostModal = ({ open, handleClose, onPostCreate }) => {
    const matches = useMediaQuery('(min-width:600px)');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: matches ? '60%' : '90%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={style}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                    <Typography color='white' variant='h6' component='h1'>
                        Create a post
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <PostForm handleClose={handleClose} onPostCreate={onPostCreate}/>
            </Box>
        </Modal>
    );
};

export default PostModal;