import React, {useEffect, useState} from 'react';
import './styles/App.css';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { ThemeProvider } from '@mui/material/styles';
import { darkTheme } from './styles/theme/theme';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import BackToTop from "./components/BackToTop";

// React Router
import { Routes, Route } from 'react-router-dom';

// Context
import { AuthProvider } from "./context/AuthContext";

// Navigation
import Navigation from './nav/Navigation';

// Pages
import Home from './pages/Home';
import About from './pages/About';
import Post from './pages/Post';
import Login from './pages/Login';

// Components
import PostModal from './components/PostModal';
import SlidingContainer from './components/SlidingContainer';
import {fetchFeed} from "./api/feed";

function App() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        // load posts and timeout for 3 seconds
        const getFeed = async () => {
            setTimeout(async () => {
                const fetchedPosts = await fetchFeed();
                setPosts(fetchedPosts);
            }, 1000)
        };
        getFeed();
    }, []);

    // update the list of posts if a post is created
    const handlePostCreate = async (newPost) => {
        await setPosts([...posts, newPost]);
    };

    // update the list of posts if a post is deleted.
    const handlePostDelete = async (deletedPostId) => {
        // console.log('This method was called...')
        const updatedPosts = posts.filter((post) => post.id !== deletedPostId);
        await setPosts(updatedPosts);
        // console.log(posts)
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="App">
            <AuthProvider>
                <ThemeProvider theme={darkTheme}>
                    <Grid container spacing={2}>
                        {/* Spacer for left column */}
                        <Grid item xs={false} md={3}></Grid>
                        {/* Middle column */}
                        <Grid item xs={12} md={6}>
                            {/* Content for middle column */}
                            <header>
                                <Navigation theme={darkTheme} handleOpenModal={handleOpenModal}/>
                            </header>
                            <Divider sx={{mb: 0}}/>
                            <main className="scrollable-content">
                                <TransitionGroup>
                                    <CSSTransition
                                        // key={}
                                        timeout={500}
                                        classNames="fade"
                                    >
                                        <SlidingContainer>
                                            <Routes>
                                                <Route
                                                    path="/"
                                                    element={
                                                        <Home
                                                            posts={posts}
                                                            onPostCreate={handlePostCreate}
                                                            onPostDelete={handlePostDelete}
                                                        />
                                                    }
                                                />
                                                <Route path="/about" element={<About/>}/>
                                                <Route path="/post/:id" element={<Post/>}/>
                                                <Route path="/login" element={<Login/>}/>
                                            </Routes>
                                        </SlidingContainer>
                                    </CSSTransition>
                                </TransitionGroup>
                                <BackToTop/>
                                <PostModal
                                    open={isModalOpen}
                                    handleClose={handleCloseModal}
                                    onPostCreate={handlePostCreate}
                                />
                            </main>
                        </Grid>
                        {/* Spacer for right column */}
                        <Grid item xs={false} md={3}></Grid>
                    </Grid>
                </ThemeProvider>
            </AuthProvider>
        </div>
    );
}

export default App;
