// Post Card - The card component for each individual post preview on the feed.
// React Core
import React, { useContext } from 'react'

// Custom Context
import AuthContext from '../context/AuthContext';

// Util functions
import {formatTime, truncateContent} from '../utils';

// MUI Components
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

// MUI Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteConfirmation from './DeleteConfirmation';

const PostCard = ({ post, style, onPostDelete }) => {
    const { user } = useContext(AuthContext);
    const navigate= useNavigate();
    const preview = truncateContent(post.plaintext, 100);

    const handleReadButtonClick = () => {
        navigate(`/post/${post.id}`);
        document.querySelector('.scrollable-content').scrollTo(0,0);
    };
    // Features on the Card
    // Date/Time of post, Title, Contents (short), Tags, Read (button)
    return (
        <Card sx={style}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {formatTime(post.published_at)}
                </Typography>
                <Typography variant="h5" component="div" gutterBottom>
                    {post.title}
                </Typography>
                <Typography sx={{ml: 4}} variant="body2" color="text.secondary">
                    {preview}
                </Typography>
                {post.tags.map((tag, index) => (
                    <Chip sx={{ mt: 2 }} key={index} label={tag} variant='outlined' size='small' />
                ))}
            </CardContent>
            <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button size="medium" variant="outlined" onClick={handleReadButtonClick}>Read</Button>
                {/* Protected view for buttons: edit and delete post */}
                {/* Right aligned box ==> across from the 'Read Post' button */}
                {/* protect view - only visible if authenticated */}
                {user && (
                    <Box>
                        <Button sx={{ mx: 1 }} variant='outlined'>
                            <EditIcon color='info'/>
                        </Button>
                        <DeleteConfirmation postId={post.id} onPostDelete={onPostDelete}/>
                    </Box>
                )}
            </CardActions>
        </Card>
    )
}
export default PostCard
