import React, { useState, useEffect } from 'react';
import Fab from '@mui/material/Fab';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

export default function BackToTop() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            setIsVisible(scrollTop > 100);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Fab
            sx={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                opacity: isVisible ? 1 : 0,
                transition: 'opacity 0.3s ease',
            }}
            color="primary"
            aria-label="back-to-top"
            onClick={scrollToTop}
        >
            <ArrowUpwardIcon />
        </Fab>
    );
}