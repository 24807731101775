import React, { useState, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DeleteIcon from "@mui/icons-material/Delete";
import { deletePost } from '../api/post';


export default function DeleteConfirmation({ onPostDelete, postId }) {
  // Auth context
  const { authTokens } = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  const handleClickOpen = () => {
    setIsDeleting(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      const response = await deletePost(postId, authTokens.access); // call the deletePost function
      console.log(response)
      if (response.status === 204) { // check if the status is 204 (No Content), which means the deletion was successful
        console.log('Post deleted successfully');
      }
    } catch (error) {
      // handle error
      console.error('Error deleting post', error);
    } finally {
      setIsDeleting(false);
      handleClose();
      onPostDelete(postId)
    }
  };

  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen}>
        <DeleteIcon color='error'/>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to Delete this post?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant='contained' color='error' onClick={handleDelete} disabled={isDeleting} autoFocus>
            {isDeleting ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}