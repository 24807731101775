// PostForm.js
import React, { useState, useContext } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { enGB } from 'date-fns/locale';
import { formatISO } from 'date-fns';
import { htmlToText } from "html-to-text";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Box, Button, Stack, TextField } from '@mui/material';
import QuillEditor from './QuillEditor';
import {LocalizationProvider} from "@mui/x-date-pickers";
import AuthContext from '../context/AuthContext';
import SendIcon from '@mui/icons-material/Send';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import { createPost } from '../api/post';

const PostForm = ({ handleClose, onPostCreate }) => {
    const [title, setTitle] = useState('');
    const [date, setDate] = useState(new Date());
    const [content, setContent] = useState('');
    const [tags, setTags] = useState('');

    // Auth context
    const { authTokens } = useContext(AuthContext);

    // slug generator
    const createSlug = (title) => {
        return title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Handle form submission here
        const formData = {
            title: title,
            plaintext: htmlToText(content),
            html: content,
            published_at: formatISO(date),
            tags: tags.split(',').map(tag => tag.trim()),
            slug: createSlug(title)
        }
        // console.log(formData);
        try {
            const data = await createPost(formData, authTokens.access);
            // console.log(data);
            onPostCreate(data);
            handleClose();
        } catch (error) {
            console.error('Error:', error)
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
            <Box component="form" onSubmit={handleSubmit} noValidate>
                <Stack spacing={3}>
                    <TextField
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        label="Title"
                        fullWidth
                    />
                    <DateTimePicker label="Date" value={date} onChange={newDate => newDate && setDate(newDate)}/>
                    <QuillEditor value={content} onChange={setContent}/>
                    <TextField
                        value={tags}
                        onChange={e => setTags(e.target.value)}
                        label="Tags"
                        fullWidth
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Button
                            sx={{ mr: 2}}
                            variant='contained'
                            type="submit"
                            endIcon={<SendIcon />}
                            fullWidth
                        >
                            Publish
                        </Button>
                        <Button
                            variant='outlined'
                            type="button"
                            onClick={handleClose}
                            endIcon={<SendAndArchiveIcon />}
                            fullWidth
                        >
                            Draft
                        </Button>
                    </Box>
                </Stack>
            </Box>
        </LocalizationProvider>
    );
};

export default PostForm;