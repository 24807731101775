// This container is for the sliding animation between feed and individual posts
import React from 'react';
import Box from "@mui/material/Box";

const SlidingContainer = ({ children }) => {
    return (
        <Box
            sx={{
                backgroundColor: 'rgba(220, 95, 0, 0.75)',
                mt: 1,
                pb: 1,
                borderRadius: 2,
                minHeight: '100vh'
            }}
        >
            {children}
        </Box>
    )
}
export default SlidingContainer
