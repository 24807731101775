import React from 'react'
import {
    Box,
    Card,
    Skeleton,
    Typography,
} from "@mui/material";

const ProjectCard = ({ project }) => {
    // add onClick event to navigate to GitHub repo for project
    const handleClick = () => {
        window.location.href = project.url;
    }

    return (
        <Card
            sx={{
                p: 2,
                boxShadow: "0 2px 3px #0000003d",
                cursor: 'pointer',
                '&:hover': {
                    opacity: 0.9,
                    boxShadow: "0 0px 10px #ffffff",
                }
            }}
            // add onClick event to navigate to GitHub repo
            onClick={handleClick}
        >
            {project ? (
                <>
                    <Box
                        sx={{
                            my: 5,
                            display: 'flex',
                            justifyContent: 'center',
                            textAlign: 'center',
                        }}
                    >
                        <Typography variant='h6' sx={{ letterSpacing: 1 }}>
                            {/* project name goes here */}
                            {project.title}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            m: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        {/* project skills/technologies go in here (listed in a row) */}
                        {project.technologies.map((skill, index) => (
                            <Typography sx={{ mr: 2 }} key={index}>
                                {skill.name}
                            </Typography>
                        ))}
                    </Box>
                </>
            ) : (
                <Skeleton variant='rectangular' width={300} height={120}/>
            )}
        </Card>
    )
}
export default ProjectCard
