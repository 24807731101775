// React Core
import React from 'react';

// MUI Components
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

// MUI Icons
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const SocialIconButtons = () => (
    // simple href > uses social urls taken from the environment variables
    <Box>
        <IconButton href={`${process.env.REACT_APP_GITHUB_SOCIAL_LINK}`}>
            <GitHubIcon />
        </IconButton>
        <IconButton href={`${process.env.REACT_APP_LINKEDIN_SOCIAL_LINK}`}>
            <LinkedInIcon />
        </IconButton>
    </Box>
);

export default SocialIconButtons;