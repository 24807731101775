import React from 'react';
import {
    Box, CircularProgress,
} from "@mui/material";

import PostCard from '../components/PostCard';


const Home = ({ posts, onPostDelete }) => {

    return (
        <>
            <Box
                sx={{
                    p: 2,
                }}
            >
                {posts.length > 0 ? [...posts].reverse().map((post) => (
                    <PostCard style={{my: 2}} key={post.id} post={post} onPostDelete={onPostDelete}/>
                )) :
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                        <CircularProgress color='secondary'/>
                    </Box>
                }
            </Box>
        </>
    )
}
export default Home
