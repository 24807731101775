// React Core
import React, { useState, useEffect } from 'react';

// React Router
import { useParams } from 'react-router-dom';

// Purify HTML
import DOMPurify from 'dompurify';

// MUI Components
import {
    Box,
    Card,
    Grid,
    CircularProgress,
} from "@mui/material";

// Custom Components
import PostTitleBar from "../components/PostTitleBar";

// API
import { fetchPost } from '../api/post';

// Post page is responsible for rendering the individual posts (full reading view)
const Post = () => {
    const { id } = useParams();
    const [post, setPost] = useState(null);

    useEffect(() => {
        // fetch the individual post by id
        const getPost = async () => {
            setTimeout(async () => {
                const fetchedPost = await fetchPost(id);
                setPost(fetchedPost);
            }, 1500)
        };
        getPost();
    }, [id]);

    if (!post) {
        return (
            <>
                <Box sx={{ p: 2}}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                        <CircularProgress color='secondary'/>
                    </Box>
                </Box>
            </>
        )
    }

    return (
        <>
            <PostTitleBar post={post}/>
            <Grid
                item
                xs={12}
                sx={{
                    px: 2
                }}
            >
                <Card
                    sx={{
                        p: 4,
                    }}
                >
                    {/*  Content  */}
                    <Box
                        sx={{
                            ml: '5%',
                            mr: '5%'
                        }}
                    >
                        {/* Post - HTML content should be pulled into here */}
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.html) }} />
                    </Box>
                </Card>
            </Grid>
        </>
    )
}
export default Post
