import { format, parseISO } from 'date-fns';

// Truncate content of blog post to a certain length for preview
export const truncateContent = (content, maxLength) => {
    if (content.length <= maxLength) {
        return content;
    }
    return content.slice(0, maxLength) + '...';
}

// Time conversion function - turn 2024-04-02T16:10:28Z into DD/MM/YYYY HH:MM 'AM/PM'
export const formatTime = (time) => {
    // dates are rendered as strings from the db
    const date = parseISO(time);
    return format(date, 'yyyy/MM/dd HH:mm');
}
